import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://4d4b0142277967832198a3dbcb77b45c@o4507610489421824.ingest.us.sentry.io/4507581522575360",
  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
});

// Remix default code
// startTransition(() => {
//   hydrateRoot(
//     document,
//     <StrictMode>
//       <RemixBrowser />
//     </StrictMode>,
//   );
// });
//
function clearBrowserExtensionInjectionsBeforeHydration() {
  for (const s of document.querySelectorAll(
    [
      "html > *:not(body, head)",
      'script[src*="extension://"]',
      'link[href*="extension://"]',
    ].join(", "),
  )) {
    s.parentNode?.removeChild(s);
  }

  const $targets = {
    html: {
      $elm: document.querySelector("html"),
      allowedAttributes: ["lang", "dir", "class"],
    },
    head: {
      $elm: document.querySelector("head"),
      allowedAttributes: [""],
    },
    body: {
      $elm: document.querySelector("body"),
      allowedAttributes: ["class"],
    },
  };

  for (const [_, target] of Object.entries($targets)) {
    if (!target.$elm) {
      continue;
    }

    for (const attr of target.$elm.getAttributeNames()) {
      if (!target.allowedAttributes.includes(attr)) {
        target.$elm?.removeAttribute(attr);
      }
    }
  }
}

function hydrate() {
  clearBrowserExtensionInjectionsBeforeHydration();

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
